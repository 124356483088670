@media screen and (max-width : 900px) {
    .mobile_view {
        display: block;
    }
    .bottom_navs{
        padding:15px 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        position: fixed;
        z-index: 20;
        bottom: 0;
        left: 0;
        border-radius: 15px 15px 0 0 ;
        box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
        user-select: none;
    }
    .bottom_nav, .active_bottom_nav{
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        color: var(--ash);
    }
    .bottom_nav span{
        font-weight: 500;
    }
    
    .active_bottom_nav{
        color: var(--main);
    }
    .active_bottom_nav span{
        font-weight: 500;
        position: relative;
        color: var(--main);
    }
    .active_bottom_nav span::after{
        content: '';
        position: absolute;
        bottom: -8px;
        right: 0;
        width: 50%;
        border-radius: 5px;
        height: 5px;
        background-color: var(--main);
        animation: pulse 0.08s linear;
    }

    @keyframes pulse {
        0% {
          left: 0;
        }
        100% {
          right: 0;
        }
      }
    
    
}