body{
  margin: 0;
  padding:0;
  font-family: "Open Sans", sans-serif;
}
:root{
  --main:#16a9d6;
}

@tailwind base;
@tailwind components;
@tailwind utilities;